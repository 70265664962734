@font-face {
  font-family: "Roboto400";
  font-style: "normal";
  font-weight: 400;
  src: local(""), url("../assets/fonts/roboto-v29-latin-regular.woff2") format("woff2"),
    url("../assets/fonts/roboto-v29-latin-regular.woff") format("woff");
}
@font-face {
  font-family: "Poppins500";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/poppins-v19-latin-500.woff2") format("woff2"),
    url("../assets/fonts/poppins-v19-latin-regular.woff") format("woff");
}
@font-face {
  font-family: "PoppinsRegular";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/poppins-v19-latin-regular.woff2") format("woff2"),
    url("../assets/fonts/poppins-v19-latin-regular.woff") format("woff");
}
@font-face {
  font-family: "Poppins600";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/poppins-v19-latin-600.woff2") format("woff2"),
    url("../assets/fonts/poppins-v19-latin-600.woff") format("woff");
}
@font-face {
  font-family: "Poppins700";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/poppins-v19-latin-700.woff2") format("woff2"),
    url("../assets/fonts/poppins-v19-latin-700.woff") format("woff");
}
@font-face {
  font-family: "Poppins800";
  font-style: normal;
  /* font-weight: 800; */
  src: url("../assets/fonts/poppins-v19-latin-800.woff2") format("woff2"),
    url("../assets/fonts/poppins-v19-latin-800.woff2") format("woff");
}
@font-face {
  font-family: "CircularStdNormal";
  font-style: normal;
  font-weight: 450;
  src: url("../assets/fonts/CircularStd-Book.woff2") format("woff2"),
    url("../assets/fonts/CircularStd-Book.woff") format("woff");
}
@font-face {
  font-family: "CircularStd700";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/CircularStd-Bold.woff2") format("woff2"),
    url("../assets/fonts/CircularStd-Bold.woff") format("woff");
}
@font-face {
  font-family: "SFProText";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/sf-pro-text-regular.woff") format("woff");
}
